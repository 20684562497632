import axios from 'axios';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import moment from 'moment';
import { lotteries } from '../util/lottery-props';

const parsedUrl = new URL(window.location.href);

function getQuery(field) {
  const value = parsedUrl.searchParams.get(field);
  return value;
}

const KEY_DATA = 'lottery-data';
const KEY_NEXT_UPDATE = 'lottery-next-update';
const KEY_VERSION = 'lottery-version';
const VERSION = '1.2.4';

async function fetchLotteryResultsData() {
  const hash = getQuery('__key');
  const response = await axios.get(`https://api.dsplay.io/qrcode/data/${hash}`);
  return response.data;
}

function getLocalStorageData() {
  let lotteryResults;

  try {
    const cache = localStorage.getItem(KEY_DATA);
    lotteryResults = cache && JSON.parse(decompressFromUTF16(cache));
  } catch (e) {
    console.warn('unable to get stored results', e);
  }
  return lotteryResults;
}

function setLocalStorageData(lotteryResults, lotteryResultValidity) {
  try {
    localStorage.setItem(KEY_DATA, compressToUTF16(JSON.stringify(lotteryResults)));
    localStorage.setItem(KEY_NEXT_UPDATE, moment(lotteryResultValidity).utc().toISOString());
    localStorage.setItem(KEY_VERSION, VERSION);
  } catch (e) {
    console.warn('error storing results', e);
  }
}

export function isLocalStorageDataValid() {
  try {
    const lastUpdate = localStorage.getItem(KEY_NEXT_UPDATE);
    if (!lastUpdate) return false;
    if (moment().diff(lastUpdate, 'hours') > 1) return false;

    const version = localStorage.getItem(KEY_VERSION);
    if (version !== VERSION) return false;

    return true;
  } catch (e) {
    console.warn('error reading localStogare', e);
  }

  return false;
}

export async function getLotteryResultsData() {
  try {
    if (isLocalStorageDataValid()) {
      return getLocalStorageData();
    }

    const { data, expiresAt } = await fetchLotteryResultsData();
    const lotteryResults = Object.keys(lotteries)
      .filter((key) => data[key])
      .map((key) => {
        const value = data[key];
        value.name = key;
        return value;
      });

    setLocalStorageData(lotteryResults, expiresAt);

    return lotteryResults;
  } catch (error) {
    throw Error('error get data');
  }
}
