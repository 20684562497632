import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import NumberFormat from 'react-number-format';
import moment from 'moment';

function NextRound({
  date,
  estimatedPrize,
}) {
  const localDate = moment().format('D');
  const contestDate = moment(date).local().format('D');
  const roundDate = localDate === contestDate ? 'Hoje' : new Date(date)
    .toLocaleString('pt', {
      weekday: 'long',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

  return (
    <div className="next-round">
      <div className="label">Próximo Concurso</div>
      <div className="date">{roundDate}</div>
      <div className="label">Prêmio Estimado</div>
      <div className="prize">
        <NumberFormat
          displayType="text"
          value={estimatedPrize}
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
        />
      </div>
    </div>
  );
}

NextRound.propTypes = {
  date: PropTypes.string.isRequired,
  estimatedPrize: PropTypes.number.isRequired,
};

export default NextRound;
