export const lotteries = {
  megasena: {
    label: 'Mega Sena',
    numbersPerRow: 6,
  },
  federal: {
    label: 'Federal',
  },
  duplasena: {
    label: 'Dupla Sena',
    numbersPerRow: 6,
  },
  quina: {
    label: 'Quina',
    numbersPerRow: 5,
  },
  lotofacil: {
    label: 'Lotofácil',
    numbersPerRow: 5,
  },
  lotomania: {
    label: 'Lotomania',
    numbersPerRow: 5,
  },
  timemania: {
    label: 'Timemania',
    numbersPerRow: 7,
  },
  diadesorte: {
    label: 'Dia de Sorte',
    numbersPerRow: 7,
  },
  supersete: {
    label: 'Super Sete',
    numbersPerRow: 7,
  },
  loteca: {
    label: 'Loteca',
  },
};

export const numbersPerRow = (lotteryName) => lotteries[lotteryName].numbersPerRow;
export const label = (lotteryName) => lotteries[lotteryName].label;
