import React from 'react';
import PropTypes from 'prop-types';

import PrizeTable from '../prizes';

import './style.sass';

function FederalContent({
  prizes,
}) {
  return (
    <>
      <PrizeTable
        prizes={prizes}
      />
    </>
  );
}

FederalContent.propTypes = {
  prizes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FederalContent;
