import React, { useEffect, useState } from 'react';

import Main from './components/main';

import './app.sass';

import { getLotteryResultsData } from './services/lottery-results-data';
import Loader from './components/loader';

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [lotteryResultsData, setLotteryResultsData] = useState([]);

  useEffect(() => {
    (async () => {
      const loadData = async () => {
        try {
          // TODO refactor-> variable name "res"
          const res = await getLotteryResultsData();
          setLotteryResultsData(res);
        } catch (e) {
          setError(e);
        }
      };
      const wait = (delay) => new Promise((resolve) => {
        setTimeout(resolve, delay);
      });

      await Promise.all([loadData(), wait(1000)]);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <div className="error">Ooops! Erro carregando dados. Por favor tente novamente :(</div>
    );
  }

  return (
    <div>
      <Main
        lotteryResultsData={lotteryResultsData}
      />
    </div>
  );
}

export default App;
