import React from 'react';
import PropTypes from 'prop-types';

import NumberList from '../numbers';
import PrizeTable from '../prizes';
import LotteryResult from '../lottery-result';

import './style.sass';

function DefaultContent({
  name,
  prizes,
  numbers,
  accumulated,
  next,
}) {
  return (
    <>
      <NumberList
        lotteryName={name}
        numbers={numbers}
      />
      <LotteryResult
        mainPrize={prizes[0]}
        accumulated={accumulated}
        nextLottery={next}
      />
      <PrizeTable
        prizes={prizes}
      />
    </>
  );
}

DefaultContent.propTypes = {
  name: PropTypes.string.isRequired,
  prizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  numbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  accumulated: PropTypes.number.isRequired,
  next: PropTypes.object,
};

DefaultContent.defaultProps = {
  next: null,
};

export default DefaultContent;
