import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './style.sass';

const camelize = (str) => {
  const arr = str.split(' ');

  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

function PrizeTable({ prizes }) {
  const mainPrize = prizes[0];

  const prizeList = prizes.map((prize) => {
    let { range } = prize;
    if (prize.range) {
      if (range.includes('hits_')) {
        range = `${range.replace('hits_', '')} acertos`;
      }
      range = range.replace('luck_month', 'Mês da Sorte')
        .replace('heart_club', 'Clube do Coração');
    }

    const rangeText = Number.isInteger(+range) ? `${+range + 1}º` : camelize(range);

    return (
      <TableRow key={prize.range}>
        <TableCell className="body-cell">{rangeText}</TableCell>
        <TableCell className="body-cell" align="right">
          {mainPrize.ticket ? prize.winners : (
            <NumberFormat
              displayType="text"
              value={parseInt(prize.winners, 10)}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
            />
          )}
        </TableCell>
        <TableCell className="body-cell" align="right">
          <NumberFormat
            displayType="text"
            value={parseInt(prize.amount, 10)}
            decimalSeparator=","
            thousandSeparator="."
            fixedDecimalScale
            decimalScale={2}
          />
        </TableCell>
      </TableRow>
    );
  });

  return (

    <TableContainer className="prizes-table-container">
      <Table
        className="prizes-table lottery-results-table"
        aria-label="prizes table"
      >
        <TableHead className="header">
          <TableRow>
            <TableCell className="header-cell"><span>{mainPrize.ticket ? 'DESTINO' : 'FAIXAS'}</span></TableCell>
            <TableCell className="header-cell" align="right"><span>{mainPrize.ticket ? 'BILHETE' : 'GANHADORES'}</span></TableCell>
            <TableCell className="header-cell" align="right"><span>PRÊMIO (R$)</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="body">
          {prizeList}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

PrizeTable.propTypes = {
  prizes: PropTypes.arrayOf(PropTypes.object),
};

PrizeTable.defaultProps = {
  prizes: [],
};

export default PrizeTable;
