import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header';
import { label } from '../../util/lottery-props';
import NextRound from '../next-round';
import DuplaSenaContent from './duplasena';
import FederalContent from './federal';
import LotecaContent from './loteca';
import DefaultContent from './default';

import './style.sass';

/*
  TODO possible refactor
  remove:
    functions -> compareRange, prizesToArray
    object -> rangeOrder
  if return array in lottery service, but at moment is not secure
  because this site is used to apresentation and change can add bugs.
*/
const rangeOrder = {
  sena: 10,
  quina: 9,
  quadra: 8,
  terno: 7,
  duque: 6,
  hits: 5,
};

function compareRange(a, b) {
  if (a.range.includes('hits') && b.range.includes('hits')) {
    if (parseInt(a.range.split('_')[1], 10) >= parseInt(b.range.split('_')[1], 10)) return 1;
    return -1;
  }
  if (a.range.includes('hits') && !b.range.includes('hits')) {
    if (rangeOrder[b.range] && rangeOrder[b.range] >= rangeOrder.hits) return -1;
    return 1;
  }
  if (!a.range.includes('hits') && b.range.includes('hits')) {
    if (rangeOrder[a.range] && rangeOrder[a.range] >= rangeOrder.hits) return 1;
    return -1;
  }
  if (rangeOrder[a.range] && rangeOrder[b.range]) {
    if (rangeOrder[a.range] >= rangeOrder[b.range]) return 1;
    return -1;
  }
  if (rangeOrder[a.range] && !rangeOrder[b.range]) return 1;
  if (!rangeOrder[a.range] && rangeOrder[b.range]) return -1;
  return -1;
}

function prizesToArray(prizes) {
  if (!prizes) return null;

  const prizeArray = Object.entries(prizes).map(([key, value]) => {
    value.range = key;
    if (value.winners === undefined || value.winners === null) value.winners = value.ticket;
    return value;
  });

  return prizeArray.sort(compareRange).reverse();
}

function TabContent({ lotteryResultData: resultData, value, index }) {
  const {
    round,
    name,
    next,
  } = resultData;

  let content = null;

  if (name === 'duplasena') {
    content = (
      <DuplaSenaContent
        name={name}
        numbers1={round.numbers1}
        numbers2={round.numbers2}
        prizes1={prizesToArray(round.prizes1)}
        prizes2={prizesToArray(round.prizes2)}
        accumulated={round.accumulated}
        next={next}
      />
    );
  } else if (name === 'federal') {
    content = (
      <FederalContent
        prizes={prizesToArray(round.prizes)}
        accumulated={round.accumulated}
        next={next}
      />
    );
  } else if (name === 'loteca') {
    content = (
      <LotecaContent
        prizes={prizesToArray(round.prizes)}
        matches={round.matches}
        accumulated={round.accumulated}
        next={next}
      />
    );
  } else {
    content = (
      <DefaultContent
        name={name}
        prizes={prizesToArray(round.prizes)}
        numbers={round.numbers}
        accumulated={round.accumulated}
        next={next}
      />
    );
  }

  return (
    <div
      className="tab-content"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
    >
      <Header
        date={round.date}
        lotteryName={name}
        title={label(name)}
        round={+round.number}
      />

      {content}

      {
        resultData.next
        && <NextRound {...resultData.next} />
      }
    </div>
  );
}

TabContent.propTypes = {
  lotteryResultData: PropTypes.object,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabContent.defaultProps = {
  lotteryResultData: null,
};

export default TabContent;
