import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { Adsense } from '@ctrl/react-adsense';

import LotteryIcon from '../lottery-icon';
import TabContent from '../content';

import './style.sass';
import { label } from '../../util/lottery-props';
import Footer from '../footer';

const KEY_SELECTED_INDEX = 'lottery-index';

function getInitialIndex() {
  try {
    return (localStorage[KEY_SELECTED_INDEX] && +localStorage[KEY_SELECTED_INDEX]) || 0;
  } catch (e) {
    return 0;
  }
}

const initialIndex = getInitialIndex();

const storeSelectedIndex = (index) => {
  try {
    localStorage.setItem(KEY_SELECTED_INDEX, index.toString());
  } catch (e) {
    console.warn('error storing index', e);
  }
};

function Main({ lotteryResultsData }) {
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex);

  // const lotteryNames = lotteryResultsData.map(({ humanReadableName }) => humanReadableName);

  const tabs = lotteryResultsData
    .map(({ name: lotteryName }) => (
      <Tab
        className={`lottery-tab lottery-${lotteryName}`}
        classes={{
          selected: 'selected-lottery',
        }}
        label={label(lotteryName)}
        icon={<LotteryIcon lotteryName={lotteryName} />}
        key={lotteryName}
      />
    ));

  const tabContents = lotteryResultsData
    .map((lotteryResultData, i) => (
      <TabContent
        value={selectedIndex}
        key={lotteryResultData.name}
        index={i}
        lotteryResultData={lotteryResultData}
      />
    ));

  const handleChange = (event, index) => {
    setSelectedIndex(index);
    storeSelectedIndex(index);
  };

  return (
    <div className="main">
      <AppBar position="fixed" color="default">
        <Tabs
          className={`tabs selected-${lotteryResultsData[selectedIndex].name}`}
          value={selectedIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable tabs"
          classes={{
            scroller: 'scroller',
            indicator: 'indicator',
            flexContainer: 'tabs-container',
          }}
        >
          {tabs}
        </Tabs>
      </AppBar>
      {tabContents}
      <Adsense
        client="ca-pub-3365244858305516"
        slot="4571011962"
        data-adtest="on"
        // style={{ textAlign: 'center' }}
        style={{ display: 'block', textAlign: 'center' }}
        // layout="in-article"
        format="auto"
        // // layout="in-article"
        responsive
      // format="auto"
      />
      <Footer />
    </div>
  );
}

Main.propTypes = {
  lotteryResultsData: PropTypes.arrayOf(PropTypes.object),
};

Main.defaultProps = {
  lotteryResultsData: [],
};

export default Main;
