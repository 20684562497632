import React from 'react';
import PropTypes from 'prop-types';

import NumberList from '../numbers';
import PrizeTable from '../prizes';
import LotteryResult from '../lottery-result';

import './style.sass';

function getMainPrizeDuplaSena(prizes1, prizes2) {
  const prize = {};
  prize.winners = prizes1[0].winners + prizes2[0].winners;
  // TODO amount in this case does not make sense because
  // value is different to each game
  // example: https://g1.globo.com/loterias/duplasena.ghtml#1797
  prize.amount = null;
  return prize;
}

function DuplaSenaContent({
  name,
  numbers1,
  numbers2,
  prizes1,
  prizes2,
  accumulated,
  next,
}) {
  return (
    <>
      <h3 className="section-title"> 1ª Sorteio </h3>
      <NumberList
        lotteryName={name}
        numbers={numbers1}
      />
      <h3 className="section-title"> 2ª Sorteio </h3>
      <NumberList
        lotteryName={name}
        numbers={numbers2}
      />
      <LotteryResult
        mainPrize={getMainPrizeDuplaSena(prizes1, prizes2)}
        accumulated={accumulated}
        nextLottery={next}
      />
      <h3 className="section-title"> 1ª Sorteio </h3>
      <PrizeTable
        prizes={prizes1}
      />

      <h3 className="section-title"> 2ª Sorteio </h3>
      <PrizeTable
        prizes={prizes2}
      />
    </>
  );
}

DuplaSenaContent.propTypes = {
  name: PropTypes.string.isRequired,
  numbers1: PropTypes.arrayOf(PropTypes.string).isRequired,
  numbers2: PropTypes.arrayOf(PropTypes.string).isRequired,
  prizes1: PropTypes.arrayOf(PropTypes.object).isRequired,
  prizes2: PropTypes.arrayOf(PropTypes.object).isRequired,
  accumulated: PropTypes.number.isRequired,
  next: PropTypes.object,
};

DuplaSenaContent.defaultProps = {
  next: null,
};

export default DuplaSenaContent;
