import React from 'react';
import PropTypes from 'prop-types';

import PrizeTable from '../prizes';
import LotteryResult from '../lottery-result';
import MatchList from '../matches';

import './style.sass';

function LotecaContent({
  prizes,
  matches,
  accumulated,
  next,
}) {
  return (
    <div className="loteca">
      <MatchList matches={matches} />
      <LotteryResult
        mainPrize={prizes[0]}
        accumulated={accumulated}
        nextLottery={next}
      />
      <PrizeTable
        prizes={prizes}
      />
    </div>
  );
}

LotecaContent.propTypes = {
  prizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  accumulated: PropTypes.number.isRequired,
  next: PropTypes.object,
};

LotecaContent.defaultProps = {
  next: null,
};

export default LotecaContent;
