import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { numbersPerRow } from '../../util/lottery-props';

function NumberList({ numbers, lotteryName }) {
  const perRow = numbersPerRow(lotteryName);
  const rowCount = Math.ceil(numbers.length / perRow);

  const rows = [];
  for (let i = 0; i < rowCount; i += 1) {
    const startIdx = i * perRow;
    const endIdx = startIdx + perRow;

    rows.push(
      <div className="number-row" key={i}>
        {
          numbers.slice(startIdx, endIdx).map((number, j) => (
            <div className="lottery-number" key={`${number + j}`}>
              {number}
            </div>
          ))
        }
      </div>,
    );
  }

  return (
    <div className={`${lotteryName}-primary-color number-list-wrapper`}>
      {rows}
    </div>
  );
}

NumberList.propTypes = {
  numbers: PropTypes.arrayOf(PropTypes.string),
  lotteryName: PropTypes.string,
};

NumberList.defaultProps = {
  numbers: [],
  lotteryName: '',
};

export default NumberList;
