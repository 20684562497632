import React from 'react';
import LotteryIcon from '../lottery-icon';
import './style.sass';

function Loader() {
  return (
    <div className="loader">
      <LotteryIcon className="logo bounce" lotteryName="caixa" />
      Carregando...
    </div>
  );
}

export default Loader;
