import React from 'react';

import './style.sass';

function Footer() {
  return (
    <div className="footer">
      {'Powered by '}
      <a href="https://dsplay.tv" target="dsplay">DSPLAY - Digital Signage</a>
    </div>
  );
}

export default Footer;
