import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './style.sass';

function Header({
  lotteryName,
  title,
  round,
  date,
}) {
  const roundDate = useMemo(() => new Date(date)
    .toLocaleString('pt', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    }), []);

  return (
    <div className={`${lotteryName}-primary-color tabpanel-header`}>
      <h1>{title}</h1>
      <div>{`Concurso nº ${round} | ${roundDate}`}</div>
    </div>
  );
}

Header.propTypes = {
  lotteryName: PropTypes.string,
  title: PropTypes.string,
  round: PropTypes.number,
  date: PropTypes.string,
};

Header.defaultProps = {
  lotteryName: '',
  title: '',
  round: 0,
  date: '',
};

export default Header;
