import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import './style.sass';

function ResultLottery({ mainPrize, accumulated, nextLottery }) {
  const estimatedPrize = nextLottery ? nextLottery.estimatedPrize : null;

  const getHeaderText = () => {
    let headerTitle = '';
    let headerSubTitle = '';

    if (mainPrize.winners === 1) {
      headerTitle = `${mainPrize.winners} GANHADOR`;
    } else if (mainPrize.winners > 1) {
      headerTitle = `${mainPrize.winners} GANHADORES`;
    } else {
      headerTitle = 'ACUMULOU';
      headerSubTitle = 'Valor acumulado';
    }

    return { headerTitle, headerSubTitle };
  };

  const { headerTitle, headerSubTitle } = getHeaderText();

  return (
    <div className="content-winners-wrapper">
      <div className="w-100 winners">
        {headerTitle}
      </div>

      {
        headerSubTitle
        && (
          <>
            <div className="sub-title">{headerSubTitle}</div>
            <div className="w-100 prize-value">
              <NumberFormat
                displayType="text"
                value={parseInt((accumulated || estimatedPrize), 10)}
                prefix="R$ "
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
              />
            </div>
          </>
        )
      }
    </div>
  );
}

ResultLottery.propTypes = {
  accumulated: PropTypes.number.isRequired,
  mainPrize: PropTypes.object,
  nextLottery: PropTypes.object,
};

ResultLottery.defaultProps = {
  mainPrize: null,
  nextLottery: null,
};

export default ResultLottery;
