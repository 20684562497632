import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import './style.sass';

function MatchList({ matches }) {
  const matchList = matches.map((match) => (
    <TableRow key={`${match.leftTeamName}-${match.rightTeam}`}>
      <TableCell className={`body-cell ${match.leftColumn ? 'winner' : ''}`} align="right">
        {match.leftTeamName}
      </TableCell>
      <TableCell className="body-cell result" align="center">
        <span className={match.leftColumn ? 'winner' : ''}>
          {match.leftTeam}
        </span>
        {' x '}
        <span className={match.rightColumn ? 'winner' : ''}>
          {match.rightTeam}
        </span>
      </TableCell>
      <TableCell className={`body-cell ${match.rightColumn ? 'winner' : ''}`}>
        {match.rightTeamName}
      </TableCell>
    </TableRow>
  ));

  return (
    <TableContainer className="matches-container">
      <Table
        className="match-results-table"
        aria-label="loteca match results"
      >
        <TableBody className="body">
          {matchList}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

MatchList.propTypes = {
  matches: PropTypes.arrayOf(PropTypes.object),
};

MatchList.defaultProps = {
  matches: [],
};

export default MatchList;
